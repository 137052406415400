import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuditRequestDto, BaseForm, addDays, daysUntil } from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { AuditRequestDataService } from '../../services/data/audit-request-data.service';

@Component({
  selector: 'app-audit-request-item-form',
  templateUrl: './audit-request-item-form.component.html',
  styleUrls: ['./audit-request-item-form.component.scss'],
})
export class AuditRequestItemFormComponent extends BaseForm<AuditRequestDto> implements OnInit {
  contextVariables: any[] = [];
  minDate = new Date();
  constructor(
    public viewModeService: ViewModeService,
    private requestService: AuditRequestDataService
  ) {
    super(viewModeService, 'AUDIT_REQUEST');
  }

  ngOnInit(): void { }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? {
        ...this.getDataKeyValueFormat(),
        linkedDocuments: this.formGroup?.controls?.attachments?.value
          ? [...this.formGroup.controls.attachments.value]
          : [],
      }
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      subject: new FormControl(null, Validators.required),
      description: new FormControl(null),
      dueDate: new FormControl(null),
      dueDaysToResponse: new FormControl(null),
      responseBy: new FormControl(null),
      requestedBy: new FormControl(null),
      attachments: new FormControl(null),
      assignedTo: new FormControl(null),
      auditCaseResult: new FormControl(null),
      // auditRequestStatus: new FormControl(null),
    });

    this.formGroup.controls.dueDaysToResponse.valueChanges.subscribe((x) => {
      this.formGroup.controls.dueDate?.patchValue(addDays(x), { emitEvent: false });
    });
    this.formGroup.controls.dueDate.valueChanges.subscribe((x) => {
      this.formGroup.controls.dueDaysToResponse?.patchValue(daysUntil(x), { emitEvent: false });
    });
  }

  get canEditFeilds() {
    return this.fieldViewMode == 'edit' ? (this.data?.auditRequestStatus == AuditRequestDto.AuditRequestStatusEnum.Closed ? 'view' : this.fieldViewMode) : this.fieldViewMode;
  }
}
